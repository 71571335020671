import React from 'react';
import { SectionHeader } from '../../../modules/common/components/section-header';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';

import '../../generic-page.scss';
import './guides.scss';
import { StaticImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';
import { Col, Row } from 'react-bootstrap';
import { YoutubeEmbed } from '../../../modules/common/components/youtube-embed';
import { faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CategoryCard } from '../../../modules/cs/main/components/category-card';

const ZZZIntroGame: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page zzz guide-zzz'} game="zzz">
      <ul className="breadcrumb">
        <li>
          <Link to="/zenless/">Zenless Zone Zero</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/zenless/guides/">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Introduction to the game</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/zzz/categories/category_intro.png"
            alt="Characters"
          />
        </div>
        <div className="page-details">
          <h1>Introduction to the game</h1>
          <h2>
            A guide containing basic information that you need to know about
            Zenless Zone Zero.
          </h2>
          <p>
            Last updated: <strong>17/07/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297229"></div>
      <div className="page-content">
        <SectionHeader title="Zenless Zone Zero" />
        <p>
          Zenless Zone Zero is an roguelike ARPG game developed by HoYoverse
          that launched globally on the <strong>4th of July 2024</strong>.
        </p>
        <p>Here's the official blurb about the game:</p>
        <blockquote>
          Zenless Zone Zero is a brand new action game. The story takes place in
          the near future, where a mysterious natural disaster known as the
          &quot;Hollows&quot; has occurred. A new kind of city has emerged in
          this disaster-ridden world known as &quot;New Eridu&quot;. This last
          'oasis' has mastered the technology to co-exist with the Hollows, and
          is home to a whole host of chaotic, boisterous, dangerous and very
          active factions. As a professional 'Proxy', you will play a crucial
          role in linking the city with the Hollows. Your story awaits you.
        </blockquote>
        <p>To learn more about the game, check out our other guides:</p>
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Beginner guide"
            link="/zenless/guides/beginner-guide"
            image={
              <StaticImage
                src="../../../images/zzz/categories/category_beginner.jpg"
                alt="Reroll guide"
              />
            }
          />
          <CategoryCard
            title="Reroll guide"
            link="/zenless/guides/reroll-guide"
            image={
              <StaticImage
                src="../../../images/zzz/categories/category_reroll.jpg"
                alt="Reroll guide"
              />
            }
          />
          <CategoryCard
            title="Tips and Tricks"
            link="/zenless/guides/tips-and-tricks"
            image={
              <StaticImage
                src="../../../images/zzz/categories/category_tips.jpg"
                alt="Tips and Tricks"
              />
            }
          />
        </Row>
        <SectionHeader title="Trailer" />
        <Row className="video">
          <Col xs={12} lg={6}>
            <YoutubeEmbed embedId="C5WS9Ohb-fI" />
          </Col>
        </Row>
        <SectionHeader title="Gameplay" />
        <Row className="video">
          <Col xs={12} lg={6}>
            <YoutubeEmbed embedId="lIc7FN4b62o" />
          </Col>
        </Row>
        <SectionHeader title="Official links" />
        <ul>
          <li>
            Official Website:{' '}
            <a
              href="https://zenless.hoyoverse.com/en-us/"
              target="_blank"
              rel="noreferrer"
            >
              <FontAwesomeIcon width="18" icon={faArrowUpRightFromSquare} />{' '}
              Open in new window
            </a>
          </li>
          <li>
            Official YouTube:{' '}
            <a
              href="https://www.youtube.com/@ZZZ_Official"
              target="_blank"
              rel="noreferrer"
            >
              <FontAwesomeIcon width="18" icon={faArrowUpRightFromSquare} />{' '}
              Open in new window
            </a>
          </li>
          <li>
            Official Discord:{' '}
            <a
              href="https://hoyo.link/dAMhFTAL"
              target="_blank"
              rel="noreferrer"
            >
              <FontAwesomeIcon width="18" icon={faArrowUpRightFromSquare} />{' '}
              Open in new window
            </a>
          </li>
          <li>
            Official Twitter:{' '}
            <a href="https://x.com/ZZZ_EN" target="_blank" rel="noreferrer">
              <FontAwesomeIcon width="18" icon={faArrowUpRightFromSquare} />{' '}
              Open in new window
            </a>
          </li>
          <li>
            Google Play Store:{' '}
            <a
              href="https://play.google.com/store/apps/details?id=com.HoYoverse.Nap"
              target="_blank"
              rel="noreferrer"
            >
              <FontAwesomeIcon width="18" icon={faArrowUpRightFromSquare} />{' '}
              Open in new window
            </a>
          </li>
          <li>
            Apple Store:{' '}
            <a
              href="https://apps.apple.com/us/app/zenless-zone-zero/id1606356401"
              target="_blank"
              rel="noreferrer"
            >
              <FontAwesomeIcon width="18" icon={faArrowUpRightFromSquare} />{' '}
              Open in new window
            </a>
          </li>
        </ul>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297229"></div>
    </DashboardLayout>
  );
};

export default ZZZIntroGame;

export const Head: React.FC = () => (
  <Seo
    title="Introduction to the game | Zenless Zone Zero | Prydwen Institute"
    description="A guide containing basic information that you need to know about Zenless Zone Zero."
    game="zzz"
  />
);
